/* eslint-disable import/no-extraneous-dependencies */
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./index.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import TagManager from "react-gtm-module";
import App from "./app";
import reportWebVitals from "./report-web-vitals";
import store from "./store/store";
import "antd/dist/antd.min.css";
import "react-quill/dist/quill.snow.css";
import "./components/locales/i18n";

const key = process.env.REACT_APP_PUBLISHABLE_KEY || "";
const stripePromise = loadStripe(key);
const persistor = persistStore(store);

const Root = () => (
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </PersistGate>
    </Provider>
    <ToastContainer />
  </StrictMode>
);

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
